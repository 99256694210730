<template>
  <div id="headerComp">
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-light py-3">
        <div class="container">
          <a class="navbar-brand" href="/">
            <img src="@/assets/images/logo.png" class="logo" />
            <span class="logoFont">{{
              isMobile ? mfycesc.replace("｜", "-") : mfycesc
              }}</span>
          </a>
          <button class="navbar-toggler navbar-toggler-end" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item mx-2">
                  <span :class="indexActive ? 'nav-link active' : 'nav-link'" @click="jumpPage('home')"><i></i>{{ sy
                    }}</span>
                </li>
                <li class="nav-item mx-2">
                  <span :class="aboutActive ? 'nav-link active' : 'nav-link'" @click="jumpPage('about')"><i></i>{{ gywm
                    }}</span>
                </li>
                <li class="nav-item mx-2">
                  <span :class="companyNewsActive ? 'nav-link active' : 'nav-link'"
                    @click="jumpPage('companyNews')"><i></i>{{ gsdt }}</span>
                </li>
                <li class="nav-item mx-2">
                  <span :class="recruitActive ? 'nav-link active' : 'nav-link'" @click="jumpPage('recruit')"><i></i>{{
                    lxwm }}</span>
                </li>
                <li class="nav-item mx-2" v-if="!isMobile">
                  <div class="languageBox">
                    <i data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2"
                      aria-expanded="false" aria-label="Toggle navigation"></i>
                    <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2"
                      aria-expanded="false" aria-label="Toggle navigation">{{ currentLanguage }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="offcanvas languageBlock" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbarLabel">
        <div class="title">
          <span>选择语言</span>
          <span class="closeLanguageBlock" data-bs-dismiss="offcanvas" aria-label="Close"></span>
        </div>
        <ul>
          <li data-bs-dismiss="offcanvas" aria-label="Close" :class="languageType == item.value ? 'active' : ''"
            v-for="item in languageOptions" :key="item.id" @click="languageChange(item.value, item.label)">
            {{ item.label }}
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script type="text/javascript">
import mixins from "@/mixins";
import { getLanguageList, getLanguageConfigList } from "@/api/index";
export default {
  name: "headerComp",
  mixins: [mixins],
  components: {},
  props: {
    fullpageRef: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentLanguage: "简体中文",
      languageType: "1",
      indexActive: false,
      aboutActive: false,
      companyNewsActive: false,
      recruitActive: false,
      fullpageInstance: null,
      languageOptions: [],
      mfycesc: "",
      sy: "",
      gywm: "",
      gsdt: "",
      lxwm: "",
      homeData: [],
      aboutUsData: [],
      companyNewsData: [],
      contactUsData: [],
      footerData: [],
    };
  },
  computed: {
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  watch: {
    "$route.path": {
      handler(toPath, fromPath) {
        switch (toPath) {
          case "/index":
            this.indexActive = true;
            if (!this.isMobile) {
              this.$nextTick(() => {
                if (this.fullpageInstance) {
                  this.fullpageInstance.moveTo(1);
                }
              });
            }
            break;
          case "/about":
            this.aboutActive = true;
            break;
          case "/companyNews":
            this.companyNewsActive = true;
            break;
          case "/recruit":
            this.recruitActive = true;
            break;
          default:
            this.companyNewsActive = true;
            break;
        }
        this.$emit(
          "changeLanguage",
          sessionStorage.getItem("languageType")
            ? sessionStorage.getItem("languageType")
            : "1"
        );
        this.currentLanguage = sessionStorage.getItem("footerCurrentLanguage")
          ? sessionStorage.getItem("footerCurrentLanguage")
          : "简体中文";
        bus.$on("pageType", (id) => {
          setTimeout(() => {
            if (id && toPath != "/index") {
              this.scrollToCard(id);
            } else {
              if (!this.isMobile) {
                this.$nextTick(() => {
                  if (this.fullpageInstance) {
                    if (id == "cpjs") {
                      this.fullpageInstance.moveTo(1);
                    } else if (id == "jjfa") {
                      this.fullpageInstance.moveTo(2);
                    } else if (id == "mfyc") {
                      this.fullpageInstance.moveTo(4);
                      this.scrollToCard(id);
                    } else if (id == "hzhb") {
                      this.fullpageInstance.moveTo(4);
                      this.scrollToCard(id);
                    }
                  }
                });
              }
            }
          }, 50);
        });
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    scrollToCard(id) {
      // 使用 Vue 的 $refs 来获取卡片元素，并滚动到其位置
      const cardElement = document.getElementById(id);
      if (cardElement) {
        cardElement.scrollTop = 0;
        // 使用原生的 scrollIntoView 方法来滚动到元素位置
        cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    async getLanguageOption() {
      const result = await getLanguageList();
      if (result.data && result.data.length) {
        this.languageOptions = result.data.map((item) => {
          return {
            label: item.dataValue,
            value: item.dataKey,
            id: item.id,
          };
        });
      }
    },
    async getLanguageConfigInfo() {
      const result = await getLanguageConfigList({ languageType: this.languageType });
      if (result.data && result.data.length) {
        result.data.forEach((item) => {
          if (item.dataKey === "mfycesc") {
            this.mfycesc = item.dataValue;
          } else if (item.dataKey === "sy") {
            this.sy = item.dataValue;
          } else if (item.dataKey === "gywm") {
            this.gywm = item.dataValue;
          } else if (item.dataKey === "gsdt") {
            this.gsdt = item.dataValue;
          } else if (item.dataKey === "lxwm") {
            this.lxwm = item.dataValue;
          }
        });
        this.homeData = result.data.filter((item) => {
          return (
            item.dataKey === "syjjfapc" ||
            item.dataKey === "syjjfasj" ||
            item.dataKey === "syxqjjfapc" ||
            item.dataKey === "syxqjjfasj" ||
            item.dataKey === "symfycpc" ||
            item.dataKey === "symfycsj1" ||
            item.dataKey === "symfycsj2" ||
            item.dataKey === "syhzhbpc" ||
            item.dataKey === "syhzhbsj"
          );
        });
        this.footerData = result.data.filter((item) => {
          return (
            item.dataKey === "gssy" ||
            item.dataKey === "cpjs" ||
            item.dataKey === "jjfa" ||
            item.dataKey === "mfyc" ||
            item.dataKey === "hzhb" ||
            item.dataKey === "fzlc" ||
            item.dataKey === "gywm" ||
            item.dataKey === "sscjs" ||
            item.dataKey === "gsdt" ||
            item.dataKey === "lxwm" ||
            item.dataKey === "gsdz" ||
            item.dataKey === "swqt" ||
            item.dataKey === "rlzp" ||
            item.dataKey === "sscqcyxgs" ||
            item.dataKey === "dzbjcy" ||
            item.dataKey === "lxdh" ||
            item.dataKey === "yx" ||
            item.dataKey === "ewm1" ||
            item.dataKey === "ewm2"
          );
        });
        this.aboutUsData = result.data.filter((item) => {
          return (
            item.dataKey === "gywmsscpc" ||
            item.dataKey === "gywmsscsj1" ||
            item.dataKey === "gywmsscsj2" ||
            item.dataKey === "gygsdz" ||
            item.dataKey === "gywm" ||
            item.dataKey === "sscjs"
          );
        });
        this.companyNewsData = result.data.filter((item) => {
          return (
            item.dataKey === "gsdt" ||
            item.dataKey === "ckgsdt" ||
            item.dataKey === "gsdtzxdt" ||
            item.dataKey === "ljxq" ||
            item.dataKey === "fb" ||
            item.dataKey === "fhgsdt" ||
            item.dataKey === "ly" ||
            item.dataKey === "ckyw"
          );
        });
        this.contactUsData = result.data.filter((item) => {
          return (
            item.dataKey === "lxwm" ||
            item.dataKey === "gygsfz" ||
            item.dataKey === "lxwmgsdz" ||
            item.dataKey === "gsmc" ||
            item.dataKey === "sscqcyxgs" ||
            item.dataKey === "gsdz" ||
            item.dataKey === "bjcysf" ||
            item.dataKey === "lxwmswqt" ||
            item.dataKey === "mfycgf" ||
            item.dataKey === "hyjrwm" ||
            item.dataKey === "qlx" ||
            item.dataKey === "gzdd" ||
            item.dataKey === "gznx" ||
            item.dataKey === "xzfw" ||
            item.dataKey === "xlyq" ||
            item.dataKey === "gwms"
          );
        });
      }
      this.$emit("homeData", this.homeData);
      this.$emit("aboutUsData", this.aboutUsData);
      this.$emit("companyNewsData", this.companyNewsData);
      this.$emit("contactUsData", this.contactUsData);
      bus.$emit("footerData", this.footerData);
    },
    languageChange(value, label) {
      sessionStorage.setItem("languageType", value);
      sessionStorage.setItem("footerCurrentLanguage", label);
      this.languageType = sessionStorage.getItem("languageType")
        ? sessionStorage.getItem("languageType")
        : "1";
      this.$emit("changeLanguage", value);
      this.currentLanguage = label;
      bus.$emit("footerCurrentLanguage", label);
      this.getLanguageConfigInfo();
    },
  },
  created() { },
  mounted() {
    if (!this.isMobile) {
      bus.$on("fullpageReady", (fullpageInstance) => {
        this.fullpageInstance = fullpageInstance;
      });
    }
    this.currentLanguage = sessionStorage.getItem("footerCurrentLanguage")
      ? sessionStorage.getItem("footerCurrentLanguage")
      : "简体中文";
    this.languageType = sessionStorage.getItem("languageType")
      ? sessionStorage.getItem("languageType")
      : "1";
    this.getLanguageOption();
    this.getLanguageConfigInfo();
  },
  destroyed() { },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  top: 0;
  z-index: 1000;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

  .nav-link {
    cursor: pointer;
  }

  .languageBox {
    padding: 8px 0;
    display: flex;
    align-items: center;

    i {
      display: block;
      cursor: pointer;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      background: url("../../assets/images/icon_diqiu_normal.png") no-repeat;
      background-size: 100% 100%;
    }
    >span{
      cursor: pointer;
    }
  }
}

@media (min-width: 992px) {
  .header {
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 240px;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

    .nav-link {
      cursor: pointer;
    }
  }
}

.logo {
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
}

.mx-2 {
  margin: 0 32px !important;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fadb14;
  position: relative;

  i {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link.active {
    color: #000;
    position: relative;

    i {
      position: absolute;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 1px;
      display: block;
      border-bottom: 1px solid #000;
    }
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: #fadb14;
  }
}

.logoFont {
  color: #000;
  margin-left: 8px;
  font-size: 16px;
}

.navbar-brand {
  margin-right: 0;
  display: flex;
  align-items: center;

  >span {
    display: inline-block;
    width: 240px;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
  }
}

@media (min-width: 992px) {
  .navbar-brand {
    margin-right: 0;
    display: flex;
    align-items: center;

    >span {
      display: inline-block;
      width: 300px;
      white-space: pre-wrap;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}

.navbar-toggler {
  padding: 0 2px;
}

@media (min-width: 992px) {
  .logo {
    width: 100px;
  }

  .logoFont {
    color: #000;
    margin-left: 20px;
    font-size: 20px;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas.offcanvas-end {
  width: 50%;
}

.languageBlock {
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  transform: translateX(100%);

  .title {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    >span {
      display: block;
    }

    .closeLanguageBlock {
      width: 24px;
      height: 24px;
      background: url("../../assets/images/x.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
    text-align: center;
    margin-top: 40px;

    li {
      line-height: 40px;

      &.active {
        color: #fadb14;
      }
    }
  }
}
</style>
