<template>
  <div id="footerComp">
    <footer class="text-white bg-cover bg-center" v-if="!isMobile">
      <div class="dark">
        <div class="overlay-content">
          <div class="container py-5">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <h3>{{ mfyc }}</h3>
                <p style="margin-top:20px">
                  <img :src="ewm1" alt="二维码" width="120px" height="120px">
                  <img :src="ewm2" alt="二维码" style="margin-left:20px" width="120px" height="120px">
                </p>
                <p class="mb-1">
                  <span class="text-small">{{ lxdh }}</span>
                  <strong class="text-big">400-080-7727</strong>
                </p>
                <p class="mb-1" style="margin-top:20px">
                  <span class="text-small">{{ yx }}</span>
                  <strong class="text-big">develop@tidycar.cn</strong>
                </p>
              </div>
              <div class="col-lg-8 col-md-8">
                <div class="flexRow">
                  <div class="block">
                    <h6>{{ gssy }}</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','cpjs')">{{ cpjs }}</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','jjfa')">{{ jjfa }}</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','mfyc')">{{ mfyc }}</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('index','hzhb')">{{ hzhb }}</a></li>
                    </ul>
                  </div>
                  <div class="block">
                    <h6>{{ gywm }}</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('about','fzlc')">{{ fzlc }}</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('about','sscjs')">{{ sscjs }}</a></li>
                    </ul>
                  </div>
                  <div class="block">
                    <h6>{{ gsdt }}</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('companyNews','gsdt')">{{ gsdt }}</a></li>
                    </ul>
                  </div>
                  <div class="block">
                    <h6>{{ lxwm }}</h6>
                    <ul class="list-unstyled">
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('contact','gsdz')">{{ gsdz }}</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('contact','swqt')">{{ swqt }}</a></li>
                      <li class="mb-2"><a class="footer-link" @click="jumpPage('contact','rlzp')">{{ rlzp }}</a></li>
                    </ul>
                  </div>
                </div>
                <div class="footRow">
                  <p>{{ sscqcyxgs }}</p>
                  <p>京ICP备20000029号</p>
                  <p>{{ dzbjcy }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer" v-if="isMobile">
      <strong>{{lxdh}}：400-080-7727</strong>
      <span>{{ sscqcyxgs }} 京ICP备20000029号</span>
      <div class="languageBoxMobile">
        <i data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2"
          aria-expanded="false" aria-label="Toggle navigation"></i>
        <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2"
                      aria-expanded="false" aria-label="Toggle navigation">{{ currentLanguageMobile }}</span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "footerComp",
  components: {
  },
  data() {
    return {
      currentLanguageMobile: '简体中文',
      mfyc: '',
      ewm1: '',
      ewm2: '',
      lxdh: '',
      yx: '',
      gssy: '',
      cpjs: '',
      jjfa: '',
      hzhb: '',
      fzlc: '',
      sscjs: '',
      gsdz: '',
      swqt: '',
      rlzp: '',
      sscqcyxgs: '',
      dzbjcy: '',
      gywm: '',
      gsdt: '',
      lxwm: ''
    }
  },
  computed: {
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  methods: {
    jumpPage(page, type) {
      if (page == 'index') {
        this.$router.push({ path: '/index' })
      } else if (page == 'about') {
        this.$router.push({ path: '/about' })
      } else if (page == 'companyNews') {
        this.$router.push({ path: '/companyNews' })
      } else if (page == 'contact') {
        this.$router.push({ path: '/recruit' })
      }
      bus.$emit('pageType', type)
    },
  },
  created() {
  },
  mounted() {
    bus.$on('footerCurrentLanguage', (data) => { 
      this.currentLanguageMobile = data
    })
    this.currentLanguageMobile = sessionStorage.getItem('footerCurrentLanguage') ? sessionStorage.getItem('footerCurrentLanguage') : this.currentLanguageMobile
    bus.$on('footerData', (data) => {
      if (data && data.length) {
        data.forEach(item => {
          if (item.dataKey === 'ewm1') {
            this.ewm1 = item.dataValue
          } else if (item.dataKey === 'ewm2') {
            this.ewm2 = item.dataValue
          } else if (item.dataKey === 'mfyc') {
            this.mfyc = item.dataValue
          } else if (item.dataKey === 'lxdh') {
            this.lxdh = item.dataValue
          } else if (item.dataKey === 'yx') {
            this.yx = item.dataValue
          } else if (item.dataKey === 'gssy') {
            this.gssy = item.dataValue
          } else if (item.dataKey === 'cpjs') {
            this.cpjs = item.dataValue
          } else if (item.dataKey === 'jjfa') {
            this.jjfa = item.dataValue
          } else if (item.dataKey === 'hzhb') {
            this.hzhb = item.dataValue
          } else if (item.dataKey === 'sscjs') {
            this.sscjs = item.dataValue
          } else if (item.dataKey === 'gsdz') {
            this.gsdz = item.dataValue
          } else if (item.dataKey === 'swqt') {
            this.swqt = item.dataValue
          } else if (item.dataKey === 'rlzp') {
            this.rlzp = item.dataValue
          } else if (item.dataKey === 'sscqcyxgs') {
            this.sscqcyxgs = item.dataValue
          } else if (item.dataKey === 'dzbjcy') {
            this.dzbjcy = item.dataValue
          } else if (item.dataKey === 'gywm') {
            this.gywm = item.dataValue
          } else if (item.dataKey === 'fzlc') {
            this.fzlc = item.dataValue
          } else if (item.dataKey === 'gsdt') {
            this.gsdt = item.dataValue
          } else if (item.dataKey === 'lxwm') {
            this.lxwm = item.dataValue
          }
        })
      }
    })
  },
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 80px;
  background: #F5F5F5;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  strong {
    font-size: 12px;
  }

  span {
    font-size: 10px;
  }
}

.dark {
  background: #262626 !important;
}

.text-small,
.text-big {
  display: block;
}

.text-small {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
}

.text-big {
  font-size: 24px;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;

  .block {


    ul {
      color: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }
  }
}

@media (min-width: 992px) {
  .flexRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;

    .block {
      margin-left: 60px;
      width: 150px;
      text-align: right;

      ul {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.footRow {
  margin: 50px 0 0 auto;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  width: 100%;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

@media (min-width: 992px) {
  .footRow {
    margin: 50px 0 0 auto;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    width: 68%;
    text-align: right;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
  }
}

.languageBoxMobile {
  text-align: center;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    display: block;
    cursor: pointer;
    margin-right: 10px;
    width: 16px;
    height: 16px;
    background: url('../../assets/images/icon_diqiu_normal.png') no-repeat;
    background-size: 100% 100%;
  }
}
</style>
